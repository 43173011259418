<template>
  <div class="app" data-teleport-target="app">
    <suspense>
      <AppRouterView />
    </suspense>
  </div>
</template>

<script lang="ts" setup>
  import { computed, onMounted } from 'vue'
  import { useColorTheme } from '@prefecthq/prefect-design'
  import AppRouterView from '@/pages/AppRouterView.vue'
  import { BASE_URL, MODE } from './utilities/meta';

  useColorTheme()
  const refreshTimerValue = 25000
  const logoutTimer = 60 * 1000 * 15
  const debounceTimer = 500
  const baseUrl = computed(() => MODE() === 'development' ? 'http://127.0.0.1:4200' : BASE_URL())

  const debounce = (callback: (...args: any[]) => void, wait: number): (...args: any[]) => void => {
      let timer: ReturnType<typeof setTimeout> | undefined

      return (...args: any[]) => {
        if (timer) clearTimeout(timer)
        timer = setTimeout(() => callback(...args), wait)
      }
    }

    const startInactivityTimer = (): void => {
      console.log('starting timer')
      let inactivityTimer: ReturnType<typeof setTimeout>

      const resetInactivityTimer = (): void => {
        const logoutUrl = '/logout?post_redirect_uri=https://prefect.dev.datma.com/login'
        
        if (inactivityTimer) clearTimeout(inactivityTimer)

        inactivityTimer = setTimeout(async () => {
          console.log('logout now')
          window.location.replace(logoutUrl)
        }, logoutTimer)
      }

      resetInactivityTimer()

      const events: string[] = ['mousedown', 'keypress', 'mousemove']
      events.forEach(event => {
        document.addEventListener(event, debounce(() => {
          console.log('resetting the timer')
          resetInactivityTimer()
        }, debounceTimer))
      })
    }

    const startRefreshTimer = (): void => {
      const refreshTimer = setInterval(async () => {
        const response = await fetch('/refresh-token', {
          method: 'GET',
          headers: {
            'Content-Type': "application/json",
          }
        })
        const message: string = await response.json()

        if (message !== "Token Refreshed") {
          console.error("There was a problem refreshing the token.")
          clearInterval(refreshTimer)
          return
        }
        console.log('refreshed timer')
      }, refreshTimerValue)
    }

  onMounted(() => {
    console.log('It is mounted', baseUrl.value)
    startInactivityTimer()
    startRefreshTimer()
  })
</script>

